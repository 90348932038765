window.$ = require('jquery')
window.jQuery = require('jquery')
import '../src/image_lazy_load'
import '../src/pharmacist_news/adjust_tab_menu_scroll_position'
import '../src/clipboard'
import '../src/eop_extends'
import '../src/gtm_extends'
import '../src/pagetop_scroll_handler'
import '../src/pharmacist_news/toggle_display_float_header'
import '../src/daily_happy_lot'
