window.addEventListener('load', () => {
  const audioElems = document.querySelectorAll('audio[data-gtm-audio-name]')
  audioElems.forEach((audio) => {
    const audioName = audio.dataset.gtmAudioName
    audio.addEventListener('play', () => {
      dataLayer.push({
        'event': 'playAudio',
        'audioName': audioName
      })
    }, {once: true})
  })
});
